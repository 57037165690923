import request from '@/core/services/api.services.request'

export function getStateList(params) {
  return request({
    url: '/state/list',
    method: 'get',
    params: params
  })
}

export function getStateByPrefix(state_prefix) {
  return request({
    url: '/state/list/'+state_prefix,
    method: 'get'   
  })
}

export function getCityByName(city_name) {
  return request({
    url: '/city/view/'+city_name,
    method: 'get'   
  })
}


export function getCityList(params) {
  return request({
    url: '/city/list',
    method: 'get',
    params: params
  })
}

export function getCityListByStateId(state_id) {
  return request({
    url: '/city/list/'+state_id,
    method: 'get'
  })
}

export function getProfile() {
  return request({
    url: '/user/get-profile',
    method: 'get'
  })
}
